<template>
  <div class="trash-index page-layout">
    <PageHeader
      title="Corbeille"
      :has-back="true"
      @back="goBack"/>

    <md-card>
      <!-- Organizations -->
      <md-list>
        <md-list-item
          to="/admin/trash/organizations">
          <md-avatar
            class="md-avatar-icon">
            <md-icon>
              business_center
            </md-icon>
          </md-avatar>

          <div
            class="md-list-item-text">
            Cabinets
          </div>
        </md-list-item>
      </md-list>
    </md-card>
  </div>
</template>

<script>
    import PageHeader from "@/components/PageHeader";

    export default {
        components : { PageHeader },
        methods    : {
            goBack() {
                window.history.back();
            },
        },
    };
</script>

<style lang="scss">
  .trash-index {
    justify-content: space-around;

    .md-card {
      margin-top: 32px;
      margin-bottom: 32px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
